/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 34번째 로그에서는 WWDC 2019에 참여하고 오신 김정(@godrm) 님을 모시고 애플의 새로운 소식들에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/godrm"
  }, "김정 (Kim Jung) (@godrm) | Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://codesquad.kr/"
  }, "코드스쿼드 | 소프트웨어 개발 교육 및 기술 컨설팅 | CODESQUAD")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.naver.com/local/siteview.nhn?code=84644873"
  }, "코드스쿼드: 네이버 지도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.naver.com/local/siteview.nhn?code=18790650"
  }, "미림타워: 네이버 지도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/33/"
  }, "stdout_033.log: Matz 블로그 부활, 기술서전, 구글 클라우드 장애, WWDC 2019 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/14_err/"
  }, "stderr_014.log: 마법 같이 이해되는 마법사의 CIDR 해설 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=zHZf9wr2Ak8"
  }, "Apple WWDC 2011: Steve Jobs' last keynote - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/newsroom/2019/03/apple-to-host-annual-worldwide-developers-conference-june-3-7-in-san-jose/"
  }, "Apple to host annual Worldwide Developers Conference June 3-7 in San Jose  - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/pyconkorea/posts/2321990088063197"
  }, "PyCon Korea - 게시물")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/06/03/ios-apps-will-run-on-macos-with-project-catalyst/"
  }, "iOS apps will run on macOS with Project Catalyst | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/xcode/swiftui/"
  }, "Xcode - SwiftUI - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://facebook.github.io/flux/"
  }, "Flux | Application Architecture for Building User Interfaces")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/ios/ios-13-preview/"
  }, "iOS 13 Preview - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/ipados/ipados-preview/"
  }, "iPadOS Preview - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Responsive_web_design"
  }, "Responsive web design - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/AppleScript"
  }, "AppleScript - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-arcade/"
  }, "Apple Arcade - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.m.wikipedia.org/wiki/Sal_Soghoian"
  }, "Sal Soghoian - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.engadget.com/2019/06/03/twitter-new-mac-app-macos-catalina/"
  }, "The Twitter app is officially returning to the Mac later this year")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alfredapp.com/whats-new/"
  }, "What's New in Alfred 4")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://apps.apple.com/us/app/alfred/id405843582?mt=12"
  }, "‎Alfred on the Mac App Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://apps.apple.com/us/app/slack/id618783545"
  }, "‎Slack on the App Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://apps.apple.com/us/app/notion-notes-tasks-wikis/id1232780281"
  }, "‎Notion - Notes, Tasks, Wikis on the App Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/sign-in-with-apple/"
  }, "Sign In with Apple - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.thequint.com/tech-and-auto/tech-news/facebook-f8-keynote-messenger-end-to-end-encryption"
  }, "Facebook F8 Keynote: Messenger Announced With End-to-End Encryption")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/news/?id=06032019j"
  }, "Mozilla Firefox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/news/?id=06032019j"
  }, "Updates to the App Store Review Guidelines - News - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://eugdpr.org/"
  }, "EUGDPR – Information Portal")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/documentation/macos_release_notes/macos_catalina_10_15_beta_release_notes"
  }, "macOS Catalina 10.15 Beta Release Notes | Apple Developer Documentation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/6/4/18651872/apple-macos-catalina-zsh-bash-shell-replacement-features"
  }, "Apple replaces bash with zsh as the default shell in macOS Catalina - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%ED%95%84%EB%A6%BD_W._%EC%8B%A4%EB%9F%AC"
  }, "필립 W. 실러 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/pro-display-xdr/"
  }, "Pro Display XDR - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://eshop.macsales.com/item/NewerTech/NUMNTIMVS/"
  }, "NewerTech NuMount VESA Adapter for Apple iMac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/shop/product/MR3C2ZM/A/vesa-mount-adapter-kit-for-imac-pro-space-gray"
  }, "Buy VESA Mount Adapter Kit for iMac Pro - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/mac-pro/"
  }, "Mac Pro - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2019/06/06/wwdc-bash-2019/"
  }, "Weezer performs at Apple's WWDC 2019 Bash - 9to5Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://falloutboy.com/"
  }, "Fall Out Boy")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=42LgsWpfO2M"
  }, "Stage Set for Lady Gaga Performance at Apple Park  - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/lambda/latest/dg/runtimes-custom.html"
  }, "Custom AWS Lambda Runtimes - AWS Lambda")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/6/3/18647146/apple-find-my-app-tracker-friends-iphone-wwdc-2019"
  }, "Apple’s new Find My app will find your devices even if they’re offline - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UC5aNQ65ADb02zEJxzb_zmYQ"
  }, "가전주부 GJJB - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kudokun.me/cast/special-wwdc2019"
  }, "Special: Objective-C Endgame (스페셜 게스트: 김정 님) — Kudo Networks")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
